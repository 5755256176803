/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
import { idConfig } from '@glowforge/builder-components/lib/util/shared-configs';

export default {
  name: 'CreateAccount',
  friendlyName: 'Create Account',
  // eslint-disable-next-line max-len
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fd2a3493bdd2c4701bd15df06f3b5dd61',
  // eslint-disable-next-line max-len
  description: "Allows creation of a new account.",
  canHaveChildren: true,
  noWrap: true,
  inputs: [
    idConfig,
    {
      name: 'accountTargeting',
      type: 'string',
      // eslint-disable-next-line max-len
      helperText: 'Account targeting to control what email users receive when they create an account',
    },
    {
      name: 'createAccountText',
      type: 'string',
      defaultValue: 'Create Account Text',
      // eslint-disable-next-line max-len
      helperText: 'text for the create account button',
    },
    {
      name: 'addFirstNameField',
      type: 'boolean',
      helperText: 'Adds First Name field to the form'
    },
    {
      name: 'firstNamePlaceholderText',
      type: 'string',
      defaultValue: 'your first name',
      // eslint-disable-next-line max-len
      helperText: 'text for the placeholder in the first name input field',
      showIf: (options) => options.get('addFirstNameField'),
    },
    {
      name: 'requireFirstName',
      type: 'boolean',
      helperText: 'Makes first name required for form submission',
      showIf: (options) => options.get('addFirstNameField'),
    },
    {
      name: 'addLastNameField',
      type: 'boolean',
      helperText: 'Adds Last Name field to the form'
    },
    {
      name: 'lastNamePlaceholderText',
      type: 'string',
      defaultValue: 'your last name',
      // eslint-disable-next-line max-len
      helperText: 'text for the placeholder in the last name input field',
      showIf: (options) => options.get('addLastNameField'),
    },
    {
      name: 'requireLastName',
      type: 'boolean',
      helperText: 'Makes last name required for form submission',
      showIf: (options) => options.get('addLastNameField'),
    },
    {
      name: 'emailPlaceholderText',
      type: 'string',
      defaultValue: 'your-email@example.com',
      // eslint-disable-next-line max-len
      helperText: 'text for the placeholder in the email input field',
    },
    {
      name: 'errorOverride',
      type: 'string',
      // eslint-disable-next-line max-len
      enum: ['unknown-error', 'email-exists-error', 'account-created', 'name-invalid-error', 'email-invalid-error'],
      // eslint-disable-next-line max-len
      helperText: 'Override to view error messages (just for testing in builder edit mode)',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Unknown error',
      id: 'unknown-error',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              text: 'Account creation failed, please try again or contact customer support.',
            },
          },
        },
      ],
    },
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Email invalid error',
      id: 'email-invalid-error',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              text: 'Email is invalid please enter a valid email address.',
            },
          },
        },
      ],
    },
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Name invalid error',
      id: 'name-invalid-error',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              text: 'Name is invalid, please enter a name without numbers or $, %, @ symbols',
            },
          },
        },
      ],
    },
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Email exists error',
      id: 'email-exists-error',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              text: 'Account already exists please log in below.',
            },
          },
        },
      ],
    },
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Account created',
      id: 'account-created',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              text: 'Account created successfully check your email to access the magic.',
            },
          },
        },
      ],
    },
  ],
};
