import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Builder } from '@builder.io/react/lite';
import { withChildren } from "@builder.io/react";
import { addIdAttribute } from '@glowforge/builder-components/lib/util/shared-configs';
import styled from 'styled-components';
import Button from '@glowforge/builder-components/lib/button';
import textStyle from '@glowforge/gf-styled/src/components/text.style';
import useBuilderUpdater from '@glowforge/builder-components/lib/util/useBuilderUpdater';
import { createUser } from '../../api/storefront';
import { clientOnly } from '../../util';

const track = (targeting, email, firstName, lastName) => {
  const eventName = `account-created-${targeting.toLowerCase()}`;
  clientOnly(() => {
    if (window.analytics) {
      window.analytics.identify({
        email,
        firstName,
        lastName,
      });
      window.analytics.track(eventName, {
        name: eventName,
        firstName,
        lastName,
        email,
      });
    }
  });
};

const StyledDiv = styled.div`${textStyle}`;

function CreateAccount({
  attributes,
  children,
  dataTestId,
  accountTargeting,
  errorOverride,
  emailPlaceholderText,
  addFirstNameField,
  firstNamePlaceholderText,
  requireFirstName,
  addLastNameField,
  lastNamePlaceholderText,
  requireLastName,
  createAccountText,
}) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateBuilderRootState } = useBuilderUpdater();

  useEffect(() => {
    if (Builder.isEditing && errorOverride) {
      if (errorOverride === 'account-created') {
        setAccountCreated(true);
        updateBuilderRootState({ accountCreated: true });
      } else {
        setError(errorOverride);
        setAccountCreated(false);
        updateBuilderRootState({ accountCreated: false });
      }
    }
  }, [errorOverride, updateBuilderRootState]);

  const validateEmail = (emailToValidate) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(emailToValidate).toLowerCase());
  };

  const validateName = (nameToValidate, isRequired) => {
    const nameString = String(nameToValidate).trim();
    if (nameString.length < 1) {
      return !isRequired;
    }
    // Regex matches check in user-service
    // eslint-disable-next-line  no-useless-escape
    const re = /[^!"#$%&()*+:;<=>?@\^_`{|}~\\]/;
    return re.test(nameString);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError('email-invalid-error');
      return;
    }
    if (!validateName(firstName, requireFirstName)) {
      setError('name-invalid-error');
      return;
    }
    if (!validateName(lastName, requireLastName)) {
      setError('name-invalid-error');
      return;
    }
    if (!accountTargeting) {
      setError('unknown-error');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await createUser({
        email,
        firstName,
        lastName,
        targeting: accountTargeting
      });
      if (response.status === 422) {
        if (response.data.errors.first_name?.length > 0
          || response.data.errors.last_name?.length > 0
        ) {
          setError('name-invalid-error');
        } else {
          setError('email-exists-error');
        }
      } else if (response.status === 500) {
        setError('unknown-error');
      } else {
        track(accountTargeting, email, firstName, lastName);
        setAccountCreated(true);
        updateBuilderRootState({ accountCreated: true });
      }
    } catch (err) {
      setError('unknown-error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const errorContent = children?.find(child => child.key === error);
  const accountCreatedContent = children?.find(child => child.key === 'account-created');

  if (accountCreated && accountCreatedContent) {
    return (
      <div {...addIdAttribute(dataTestId)} style={{ width: '100%' }}>
        {accountCreatedContent}
      </div>
    );
  }

  return (
    <StyledDiv {...addIdAttribute(dataTestId)} {...attributes}>
      {errorContent && (
        <div className='error-message'>
          {errorContent}
        </div>
      )}
      {addFirstNameField &&
        <input
          type='text'
          maxLength={30}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder={firstNamePlaceholderText}
          required={requireFirstName}
        />}
      {addLastNameField &&
        <input
          type='text'
          maxLength={30}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder={lastNamePlaceholderText}
          required={requireLastName}
        />}
      <input
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={emailPlaceholderText}
        required
      />
      <Button
        type='submit'
        disabled={isSubmitting}
        attributes={{ onClick: handleSubmit }}
        variant='primary'
        text={isSubmitting ? 'Creating Account...' : createAccountText}
        noMobileBoxShadow={false}
      />
    </StyledDiv>
  );
}

CreateAccount.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  accountTargeting: PropTypes.string,
  errorOverride: PropTypes.oneOf([
    'unknown-error',
    'email-exists-error',
    'account-created',
    'email-invalid-error',
    'name-invalid-error',
  ]),
  addFirstNameField: PropTypes.bool,
  firstNamePlaceholderText: PropTypes.string,
  requireFirstName: PropTypes.bool,
  addLastNameField: PropTypes.bool,
  lastNamePlaceholderText: PropTypes.string,
  requireLastName: PropTypes.bool,
  emailPlaceholderText: PropTypes.string,
  createAccountText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
};

CreateAccount.defaultProps = {
  children: [],
  dataTestId: null,
  accountTargeting: null,
  errorOverride: null,
  addFirstNameField: false,
  firstNamePlaceholderText: 'Enter your first name',
  requireFirstName: false,
  addLastNameField: false,
  lastNamePlaceholderText: 'Enter your last name',
  requireLastName: false,
  emailPlaceholderText: 'Enter your email',
  createAccountText: 'Create Account',
  attributes: {},
};

export default withChildren(CreateAccount);
