import { useContext } from 'react';
import { BuilderStoreContext } from '@builder.io/react/lite';

export default function useBuilderUpdater() {
  const builderContext = useContext(BuilderStoreContext);

  const updateContentData = (newData) => {
    if (builderContext && builderContext.content) {
      Object.assign(builderContext.content.data, newData);
      builderContext.update();
    }
  };

  const updateBuilderRootState = (newState) => {
    if (builderContext && builderContext.rootState) {
      Object.assign(builderContext.rootState, newState);
      builderContext.update();
    }
  };

  return { updateContentData, updateBuilderRootState };
}